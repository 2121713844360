<template>
  <div class="">
    <div class="card">
      <div class="card-body">
        <h4
          class="card-title"
          :class="$i18n.locale == 'ar' ? 'hacen_algeria' : 'txt-left'"
        >
          {{ label }}
        </h4>

        <!-- Name -->
        <div v-if="hasName" class="form-group col-12">
          <label
            for="input3"
            class="col-form-label"
            :class="$i18n.locale == 'ar' ? 'font-size-15' : 'float-left'"
          >
            {{ labelName }}
          </label>
          <input
            id="input3"
            type="text"
            autocomplete="off"
            :readonly="$props.readonly"
            class="form-control"
            :class="$i18n.locale == 'ar' ? 'font-size-15' : ''"
            v-model="row.name"
            v-on:input="onFormChange"
            required=""
          />
        </div>
        <!-- End Name -->

        <!-- Name -->
        <div v-if="hasUsername" class="form-group col-12">
          <label
            for="input3"
            class="col-form-label"
            :class="$i18n.locale == 'ar' ? 'font-size-15' : 'float-left'"
          >
            {{ labelUsername }}
          </label>
          <input
            id="input3"
            type="text"
            autocomplete="off"
            :disabled="disableUserName"
            class="form-control"
            :class="[$i18n.locale == 'ar' ? 'font-size-15' : '']"
            v-model="row.username"
            v-on:input="onFormChange"
            required=""
          />
        </div>
        <!-- End Name -->

        <!-- Email -->
        <div v-if="hasEmail" class="form-group col-12">
          <label
            for="input4"
            class="col-form-label"
            :class="$i18n.locale == 'ar' ? 'font-size-15' : 'float-left'"
          >
            {{ labelEmail }}
          </label>
          <input
            id="input4"
            type="email"
            autocomplete="off"
            class="form-control"
            :readonly="$props.readonly"
            :class="$i18n.locale == 'ar' ? 'font-size-15' : ''"
            v-model="row.email"
            v-on:input="onFormChange"
            required=""
          />
        </div>
        <!-- End Email -->

        <!-- Email -->
        <div v-if="hasCivilID" class="form-group col-12">
          <label
            for="input4"
            class="col-form-label"
            :class="$i18n.locale == 'ar' ? 'font-size-15' : 'float-left'"
          >
            {{ labelCivilID }}
          </label>
          <input
            id="input4"
            autocomplete="off"
            type="number"
            class="form-control"
            :readonly="$props.readonly"
            :class="$i18n.locale == 'ar' ? 'font-size-15' : ''"
            v-model="row.civil_id"
            v-on:input="onFormChange"
            required=""
          />
        </div>
        <!-- End Email -->

        <!-- Password -->
        <div v-if="hasPassword" class="form-group col-12">
          <label
            for="input5"
            class="col-form-label"
            :class="$i18n.locale == 'ar' ? 'font-size-15' : 'float-left'"
          >
            {{ labelPassword }}
          </label>
          <input
            id="input5"
            type="password"
            autocomplete="off"
            class="form-control"
            :class="$i18n.locale == 'ar' ? 'font-size-15' : ''"
            v-model="row.password"
            v-on:input="onFormChange"
            :placeholder="name ? '****************' : ''"
          />
        </div>
        <!-- End Password -->

        <!-- Country Code -->
        <div v-if="hasCurrency" class="form-group col-12">
          <label
            for="input6"
            class="col-form-label"
            :class="$i18n.locale == 'ar' ? 'font-size-15' : 'float-left'"
          >
            {{ labelCurrency }}
          </label>

          <select
            class="form-control"
            :class="$i18n.locale == 'ar' ? 'font-size-15' : ''"
            v-model="row.currency"
            @change="onFormChange"
          >
            <option value="USD">
              {{ $t("app.usd") }}
            </option>
            <option value="KWD">
              {{ $t("app.kwd") }}
            </option>
            <option value="EGP">
              {{ $t("app.egp") }}
            </option>
          </select>
        </div>
        <!-- End Mobile -->

        <!-- Mobile -->
        <div v-if="hasMobile" class="form-group col-12">
          <CardMobile
            :label="$t('auth.mobile')"
            :mobile="row.mobile"
            :country_code="row.mobile_country_code"
            @mobileChange="handleMobileChange"
          />
        </div>
        <!-- End Mobile -->

        <!-- Mobile -->
        <div v-if="hasWhatsApp" class="form-group col-12">
          <CardMobile
            :label="$t('auth.whatsapp')"
            :mobile="row.whatsapp"
            :country_code="row.whatsapp_country_code"
            @mobileChange="handleWhatsAppChange"
          />
        </div>
        <!-- End Mobile -->

        <!-- Title -->
        <div v-if="hasTitle" class="form-group col-12">
          <label
            for="input8"
            class="col-form-label"
            :class="$i18n.locale == 'ar' ? 'font-size-15' : 'float-left'"
          >
            {{ labelTitle }}
          </label>
          <input
            id="input8"
            type="text"
            class="form-control"
            @paste="onPaste"
            :class="$i18n.locale == 'ar' ? 'font-size-15' : ''"
            v-model="row.title"
            v-on:input="onInputChange"
          />
        </div>
        <!-- End Title -->

        <!-- Body -->
        <div v-if="hasBody" class="form-group col-12">
          <label
            for="editor13"
            class="col-form-label"
            :class="$i18n.locale == 'ar' ? 'font-size-15' : 'float-left'"
          >
            {{ labelBody }}
          </label>
          <editor
            id="editor13"
            v-model="row.body"
            :readonly="$props.readonly"
            v-on:keyPress="onFormChange"
            :api-key="editor.api_key"
            :init="{
              height: 300,
              menubar: editor.menubar,
              plugins: editor.plugins,
              toolbar: editor.toolbar,
            }"
          />
        </div>
        <!-- End Body -->
      </div>
    </div>
  </div>
</template>

<script>
import CardMobile from "@/components/CardMobile";
import { defineComponent } from "vue";

export default defineComponent({
  name: "FormSingleLang",
  components: {
    CardMobile,
  },
  props: [
    "label",
    "bus",
    "labelName",
    "labelCurrency",
    "labelEmail",
    "labelCountryCode",
    "labelMobile",
    "labelPassword",
    "labelCivilID",
    "labelWhatsApp",
    "labelProvider",
    "labelProviderURL",
    "labelTitle",
    "labelBody",
    "labelUsername",

    "hasCurrency",
    "hasName",
    "hasEmail",
    "hasCountryCode",
    "hasMobile",
    "hasPassword",
    "hasCivilID",
    "hasTitle",
    "hasBody",
    "hasWhatsApp",
    "hasUsername",

    "currency",
    "name",
    "email",
    "civil_id",
    "mobile",
    "country_code",
    "whatsapp_country_code",
    "mobile_country_code",
    "password",
    "provider",
    "provider_url",
    "title",
    "body",
    "whatsapp",
    "username",

    "disableUserName",
    "readonly",
    "no_space",
  ],
  data() {
    return {
      row: {
        provider: this.$props.provider,
        provider_url: this.$props.provider_url,
        name: this.$props.name,
        email: this.$props.email,
        country_code: this.$props.country_code,
        whatsapp_country_code: this.$props.whatsapp_country_code,
        mobile_country_code: this.$props.mobile_country_code,
        mobile: this.$props.mobile,
        password: this.$props.password,
        currency: this.$props.currency,
        civil_id:
          this.$props.civil_id != null
            ? this.$props.civil_id
            : this.$props.mobile,
        username: this.$props.username,
        whatsapp: this.$props.whatsapp,
        title: this.$props.title,
        body: this.$props.body,
      },

      editor: {
        api_key: window.editor_apiKey,
        menubar: window.editor_menubar,
        plugins: window.editor_plugins,
        toolbar: window.editor_toolbar,
      },
    };
  },
  created() {
    console.log(this.bus);
    if (this.bus !== undefined) {
      this.bus.on("last_invoice_title", (input) => {
        this.row.title = input;
      });
    }
  },
  methods: {
    onFormChange() {
      const data = {
        provider: this.row.provider,
        provider_url: this.row.provider_url,

        name: this.row.name,
        email: this.row.email,
        country_code: this.row.country_code,
        whatsapp_country_code: this.row.whatsapp_country_code,
        mobile_country_code: this.row.mobile_country_code,
        civil_id: this.row.civil_id,
        username: this.row.username,
        mobile: this.row.mobile,
        password: this.row.password,
        currency: this.row.currency,
        whatsapp: this.row.whatsapp,

        title: this.row.title,
        body: this.row.body,
      };
      this.$emit("formChange", data);
    },

    onPaste() {
      if (this.$props.no_space) {
        let str = this.row.title;
        this.preventSpace(str);
      }
    },

    onInputChange() {
      if (this.$props.no_space) {
        let str = this.row.title;
        this.preventSpace(str);
      }
      this.onFormChange();
    },

    preventSpace(str) {
      if (!str) {
        let str = this.row.title;
        this.row.title = str.replace(/\s+/g, "_");
      } else {
        this.row.title = str.replace(/\s+/g, "_");
      }
    },

    handleMobileChange(event) {
      this.row.mobile = event.mobile;
      this.row.mobile_country_code = event.code;
      this.onFormChange();
    },

    handleWhatsAppChange(event) {
      this.row.whatsapp = event.mobile;
      this.row.whatsapp_country_code = event.code;
      this.onFormChange();
    },
  },
});
</script>
