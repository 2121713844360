<template>
  <div class="card">
    <div>
      <label
        for="input1"
        class="col-form-label"
        :class="$i18n.locale == 'ar' ? 'font-size-15' : ''"
      >
        {{ label }}
      </label>
      <div class="form-group row">
        <div class="input-group-btn col-9">
          <input
            id="slug"
            placeholder="(555) 123-4567"
            class="form-control text-lowercase"
            type="number"
            v-model="row.mobile"
            name="phone"
            @keydown.space.prevent
            v-on:input="onChange"
          />
        </div>
        <div class="input-group-btn col-3" v-if="countries.length">
          <el-select
            style="width: 100%"
            class="form-select-solid"
            v-model="row.code"
            :placeholder="row.code"
          >
            <el-option
              v-for="country in countries"
              :key="country.id"
              :class="$i18n.locale == 'ar' ? 'hacen_algeria' : ''"
              :label="country.code"
              :value="country.code"
            >
              <a
                href="#"
                @click.prevent="changeCode(country.code)"
                class="dropdown-item justify-content-between"
              >
                <span class="dial-code col-2">{{
                  country.title + " " + country.code
                }}</span>
              </a>
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import iziToast from "izitoast";
import { debounce } from "lodash";
export default {
  name: "CardMobile",
  props: ["label", "mobile", "country_code", "bus"],
  data() {
    return {
      dataLoading: false,
      auth: {
        id_token: "",
      },
      countries: [],
      row: {
        mobile: this.$props.mobile,
        code: this.$props.country_code ? this.$props.country_code : "+965",
      },
    };
  },
  created() {
    if (localStorage.getItem("id_token")) {
      this.auth.id_token = localStorage.getItem("id_token");
    }
    if (this.bus != undefined) {
      this.bus.on("mobile_changed", (mobile) => {
        this.row.mobile = mobile;
      });
    }
    this.fetchCountries();
  },
  methods: {
    fetchCountries() {
      this.dataLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.id_token,
      };

      const options = {
        url: "/countries",
        method: "GET",
        data: {},
        params: {
          paginate: 1000,
        },
      };
      this.axios(options)
        .then((res) => {
          this.dataLoading = false;
          this.countries = res.data.data.rows;
          if (!this.row.code) this.row.code = this.countries[0].code;
        })
        .catch((err) => {
          this.dataLoading = false;
          iziToast.warning({
            icon: "bx bx-angry",
            title: "",
            message: err.response ? err.response.data.message : "" + err,
          });
        });
    },

    onChange: debounce(function () {
      const data = {
        mobile: this.row.mobile,
        code: this.row.code,
      };
      this.$emit("mobileChange", data);
    }, 250),
    preventSpace(str) {
      if (!str) {
        let str = this.row.mobile;
        this.row.mobile = str.replace(/\s+/g, "-");
      } else {
        this.row.mobile = str.replace(/\s+/g, "-");
      }
    },
    changeCode(code) {
      this.row.code = code;
    },
  },
};
</script>

<style scoped></style>
